import React from "react"
import { useWpContactsQuery } from "../hooks/useWpContacts"
import Seo from "../components/Seo"
const Contacts = () => {
  const { wpPage } = useWpContactsQuery()

  return (
    <>
      <Seo title="Contacts" />
      <div>
        {wpPage.content !== null && (
          <div
            dangerouslySetInnerHTML={{
              __html: `${wpPage.content}`,
            }}
          />
        )}
      </div>
    </>
  )
}

export default Contacts
